<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow"
    :class="[
      {
        expanded:
          !isVerticalMenuCollapsed ||
          (isVerticalMenuCollapsed && isMouseHovered),
      },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light',
    ]"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  >
    <!-- main menu header-->
    <div class="navbar-header expanded">
      <slot
        name="header"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon"
      >
        <ul class="nav navbar-nav flex-row">
          <!-- Logo & Text -->
          <li class="nav-item mr-auto">
            <b-link style="margin: 0px" class="navbar-brand w-100" to="/">
              <vc-logo
                class="mt-1 mb-1"
                :imageUrl="!isVerticalMenuCollapsed ? 'logo3.png' : 'logo.png'"
                :width="!isVerticalMenuCollapsed ? 160 : 35"
              />
            </b-link>
          </li>

          <!-- Toggler Button -->
          <li class="nav-item nav-toggle">
            <b-link class="nav-link modern-nav-toggle">
              <feather-icon
                icon="XIcon"
                size="20"
                class="d-block d-xl-none"
                @click="toggleVerticalMenuActive"
              />
              <feather-icon
                :icon="collapseTogglerIconFeather"
                size="20"
                class="d-none d-xl-block collapse-toggle-icon"
                @click="toggleCollapsed"
              />
            </b-link>
          </li>
        </ul>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div :class="{ 'd-block': shallShadowBottom }" class="shadow-bottom" />

    <!-- main menu content-->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area"
      tagname="ul"
      @ps-scroll-y="
        (evt) => {
          shallShadowBottom = evt.srcElement.scrollTop > 0;
        }
      "
    >
      <vertical-nav-menu-items
        :items="itemsMenu"
        class="navigation navigation-main"
      />
    </vue-perfect-scrollbar>
    <!-- /main menu content-->
  </div>
</template>

<script>
import navMenuItems from '@/navigation/vertical';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import { BLink } from 'bootstrap-vue';
import { provide, computed, ref } from '@vue/composition-api';
import useAppConfig from '@core/app-config/useAppConfig';
import { $themeConfig } from '@themeConfig';
import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue';
import useVerticalNavMenu from './useVerticalNavMenu';

export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    // BImg,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  data(){
    return {
      workspace_name: '',
      itemsMenu: [],
      navMenuItems,
      accesses: {
        is_admin: false,
        is_manager: false,
        is_financer: false,
        is_system_admin: false,
        is_common: false,
      },
    }
  },
  watch: {
    user(newVal) {
      if (newVal) {
        this.permissions();
      }
    },
  },

  computed: {
    user() {
      return this.$store.getters.user.accesses.find(
        (a) => a.workspace_id == this.currentWorkspace.id
      );
    },
    currentWorkspace() {
      return this.$store.getters.currentWorkspace;
    },
  },

  async mounted() {
    if (this.user) {
      this.permissions();
    }
  },

  methods: {
    permissions() {
      this.accesses.is_system_admin = this.$store.getters.user.is_admin;
      this.accesses.is_admin = this.user.is_admin;
      this.accesses.is_manager = this.user.is_manager;
      this.accesses.is_transaction_manager = this.user.is_transaction_manager;
      if (
        this.user.is_admin == false &&
        this.user.is_manager == false &&
        this.user.is_transaction_manager == false
      ) {
        this.accesses.is_common = true;
      }
    
      this.user_workspaces = this.$store.getters.user.accesses.length > 1 ? true : false;

      const filterMenuItems = (items) => {
        return items
          .filter((item) => {
            if (this.accesses) {
              return (
                (this.accesses.is_admin && item.is_admin) ||
                (this.accesses.is_manager && item.is_manager) ||
                (this.accesses.is_transaction_manager && item.is_transaction_manager) ||
                (this.accesses.is_common && item.is_common) ||
                (this.accesses.is_system_admin && item.is_system_admin) || 
                (this.user_workspaces && item.user_workspaces)
              );
            }
            return false;
          })
          .map((item) => {
            if (item.children && Array.isArray(item.children)) {
              return {
                ...item,
                children: filterMenuItems(item.children),
              };
            }
            return item;
          })
          .filter((item) => {
            return !item.children || item.children.length > 0;
          });
      };
      this.itemsMenu = filterMenuItems(this.navMenuItems);
    },
  },
  setup(props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props);

    const { skin } = useAppConfig();

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false);

    provide('isMouseHovered', isMouseHovered);

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    };

    const collapseTogglerIconFeather = computed(() =>
      collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'
    );

    // App Name
    const { appName, appLogoImage } = $themeConfig.app;

    return {
      navMenuItems,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,
    };
  },
};
</script>

<style lang="scss">
@import '~@core/scss/base/core/menu/menu-types/vertical-menu.scss';
</style>
