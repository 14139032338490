export default [
  {
    title: 'Administração',
    icon: 'PieChartIcon',
    is_system_admin: true,
    children: [
      {
        title: 'Áreas de trabalho',
        route: 'workspaces',
        icon: 'FileIcon',
        is_system_admin: true,
      },
      {
        title: 'Usuarios',
        route: 'users',
        icon: 'UserIcon',
        is_system_admin: true,
      },
    ],
  },
  {
    title: 'Dashboard',
    route: 'home',
    icon: 'HomeIcon',
    is_admin:true,
    is_manager:true,
    is_transaction_manager: true,
    is_common:false
  },
  {
    title: 'Gestão',
    icon: 'ClipboardIcon',
    is_system_admin: true,
    is_admin:true,
    is_manager:true,
    is_common:true,
    children: [
      {
        title: 'Colaboradores',
        route: 'colaboradores',
        icon: 'UserIcon',
        is_system_admin: true,
        is_admin:true,
      },
      {
        title: 'Minhas tarefas',
        route: 'minhas-tarefas',
        icon: 'ListIcon',
        is_system_admin: true,
        is_admin:true,
        is_manager:true,
        is_common:true
      },
      {
        title: 'Tarefas da equipe',
        route: 'tarefas-da-equipe',
        icon: 'CalendarIcon',
        is_system_admin: true,
        is_admin:true,
        is_manager:true,
        is_common:false

      },
      {
        title: 'Projetos',
        route: 'projetos',
        icon: 'GridIcon',
        is_system_admin: true,
        is_admin:true,
        is_manager:true,
        is_common:false

      },
      {
        title: 'Exportação de dados',
        route: 'exportar-dados',
        icon: 'FileTextIcon',
        is_system_admin: true,
        is_admin:true,
        is_manager:true,
        is_common:false

      },
    ],
  },
  {
    title: 'Financeiro',
    icon: 'BarChartIcon',
    is_system_admin: true,
    is_admin:true,
    is_transaction_manager:true,
    is_common:false,
    children: [
      {
        title: 'Receitas gerais',
        route: 'lancamento-de-receitas',
        icon: 'PercentIcon',
        is_system_admin: true,
        is_admin:true,
        is_transaction_manager:true,
        is_common:false,
      },
      {
        title: 'Despesas gerais',
        route: 'lancamento-de-despesas',
        icon: 'PercentIcon',
        is_system_admin: true,
        is_admin:true,
        is_transaction_manager:true,
        is_common:false,
      },
      {
        title: 'Impostos',
        route: 'taxes',
        icon: 'PercentIcon',
        is_system_admin: true,
        is_admin:true,
        is_transaction_manager:true,
        is_common:false,
      },
      {
        title: 'Fornecedores',
        route: 'suppliers',
        icon: 'TruckIcon',
        is_system_admin: true,
        is_admin:true,
        is_transaction_manager:true,
        is_common:false,
      },
      {
        title: 'Custos de projeto',
        route: 'custos-de-projeto',
        icon: 'PieChartIcon',
        is_system_admin: true,
        is_admin:true,
        is_transaction_manager:true,
        is_common:false,
      },
      {
        title: 'Custos de escritório',
        route: 'custos-de-escritorio',
        icon: 'PieChartIcon',
        is_system_admin: true,
        is_admin:true,
        is_transaction_manager:true,
        is_common:false,
      },
      {
        title: 'Categoria de custo',
        route: 'costsCategory',
        icon: 'PieChartIcon',
        is_system_admin: true,
        is_admin:true,
        is_transaction_manager:true,
        is_common:false,
      },
      {
        title: 'Bens depreciáveis',
        route: 'depreciableAssets',
        icon: 'ArchiveIcon',
        is_system_admin: true,
        is_admin:true,
        is_transaction_manager:true,
        is_common:false,
      },
      {
        title: 'Calculos de custo',
        route: 'calculo-de-custos',
        icon: 'DollarSignIcon',
        is_system_admin: true,
        is_admin:true,
        is_transaction_manager:true,
        is_common:false,
      },
    ],
  },
  {
    title: 'Mudar workspace',
    route: 'trocar-workspace',
    icon: 'LogInIcon',
    user_workspaces: true
  },
];
